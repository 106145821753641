import React, { Component } from 'react';

class Footer extends Component {
   render() {
      return (
         <footer>

            <div className="row">
               <div className="twelve columns">
                  <ul className="copyright">
                     <li>&copy; Copyright 2021 Xuiri Electronic</li>
                  </ul>
               </div>
            </div>
         </footer>
      );
   }
}

export default Footer;
